import { ConsentNoticeTemplateConfig, Vendor } from '@types';
import { defineVendorType } from '@utils';
type EnumAddedVendors = 'iab' | 'atp' | 'custom';

interface addVendorsConfig {
  addMethod: 'merge' | 'overwrite';
  addedVendors: EnumAddedVendors[];
}

interface PrepareTemplateConfigArgs {
  addVendorsConfig: addVendorsConfig;
  templateConfig: ConsentNoticeTemplateConfig;
  vendors: Vendor[];
  partnerList: Vendor[];
}

/**
 * Compute vendors and configuration choosed by the user in order to update the template config
 * Actually only iab vendor can be added by merge or overwrite in the list
 * Other choices will come in next iterations
 */
export const prepareTemplateConfig = ({ addVendorsConfig, vendors, templateConfig, partnerList }: PrepareTemplateConfigArgs) => {
  // Deep copy config to disable ref
  templateConfig = JSON.parse(JSON.stringify(templateConfig));
  const method = addVendorsConfig?.addMethod || 'merge';
  const addedVendors = addVendorsConfig?.addedVendors || ['iab'];
  let managedVendors = [];
  managedVendors = vendors.filter(vendor => {
    const vendorType = defineVendorType(vendor);
    return vendorType !== 'unmatched' && addedVendors.includes(vendorType);
  });
  const vendorIds = managedVendors.map(vendor => vendor.id).filter(id => typeof id === 'string');
  const vendorIabIds = managedVendors.map(vendor => vendor.id).filter(id => typeof id === 'number');
  if (method === 'merge') {
    let config: any[] = templateConfig.config.app.vendors.include;
    // let iabConfig: any[] = templateConfig.config.app.vendors.iab.include;
    vendorIds.forEach(id => {
      config.push(id);
    });
    vendorIabIds.forEach(id => {
      const iabPartner = partnerList?.find(elm => elm?.namespaces?.iab2 === id && !elm?.organization_id);
      iabPartner && config.push(iabPartner.id);
    });
    templateConfig.config.app.vendors.include = [...Array.from(new Set(config))];
  } else if (method === 'overwrite') {
    const arr: string[] = [...Array.from(new Set(vendorIds))];
    vendorIabIds.forEach(id => {
      const iabPartner = partnerList?.find(elm => elm?.namespaces?.iab2 === id && !elm?.organization_id);
      iabPartner && arr.push(iabPartner.id);
    });
    templateConfig.config.app.vendors.iab.all = false;
    templateConfig.config.app.vendors.include = [...Array.from(new Set(arr))];
    // templateConfig.config.app.vendors.iab.include = iabArr; // fix this
  }
  return templateConfig;
};
