import { useAuthToken, useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { PaginatedResponse, RootProperty } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useProperty = ({ withReports = true }) => {
  const { organizationId } = useActiveOrganization();
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const fetchOrganizationProperties = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<RootProperty>>(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/properties?organization_id=${organizationId}${withReports ? '&$with[]=latest_reports' : ''}&$limit=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const organizationProperties = await axiosWithInterceptors.get<PaginatedResponse<any>>(
        `${CONFIG.environmentConfig.apiBaseUrl}properties?organization_id=${organizationId}&$sort[created_at]=-1&$limit=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const didomiProperties = organizationProperties.data;
      let responseData = response.data;
      responseData.data = didomiProperties.data
        .map(property => {
          if (responseData.data.find(agnoProperty => agnoProperty.id === property.id)) {
            return {
              ...property,
              ...responseData.data.find(agnoProperty => agnoProperty.id === property.id),
            };
          }
          property.collects = [];
          return property;
        })
        .filter(property => property?.id);
      return responseData;
    } catch (error) {
      throw error.response.status;
    }
  };
  const results = useQuery<PaginatedResponse<RootProperty>, AxiosError>(['properties', organizationId], fetchOrganizationProperties, {
    refetchInterval: false,
    // refetchInterval: properties.filter(property => ) && 1000,
    // refetchIntervalInBackground: true,
  });

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
