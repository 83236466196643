// Hash map of ISO Country code to language icon(https://uilib.didomi.io/?path=/story/atoms-illustrations-languages--didomi-language-flags) and label
// List of supported counties is taken from https://gitlab.com/didomi/agnostik/agnostik-compliance-report-spa/-/blob/main/src/utils/countrylist.ts
export const COUNTRY_CELL_DATA_PER_COUNTRY_CODE = {
  BEL: { icon: 'fr-BE', label: 'Belgium' },
  CAN: { icon: 'fr-CA', label: 'Canada' },
  DEU: { icon: 'de', label: 'Germany' },
  ESP: { icon: 'es', label: 'Spain' },
  FRA: { icon: 'fr', label: 'France' },
  GBR: { icon: 'en-GB', label: 'Great Britain' },
  USA: { icon: 'en', label: 'United States' },
};
