import { useActiveOrganization, useAuthToken } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Vendor } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const usePropertyReportVendors = ({ ids }) => {
  const token = useAuthToken();
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const fetchVendorsFromReportIds = async ids => {
    if (ids && ids.length) {
      try {
        const response = await axiosWithInterceptors.post<Vendor[]>(
          `${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/collected-vendors?organization_id=${organizationId}&bypassMetadataPartnersMapping=false`,
          {
            report_ids: ids,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        return response.data;
      } catch (error) {
        throw new Error(error.message);
      }
    }
  };
  const results = useQuery<Vendor[], AxiosError>([`property-report-vendors`, ids], () => fetchVendorsFromReportIds(ids), {
    refetchInterval: false,
  });

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
