import React, { useMemo, useState } from 'react';
import { DidomiFiltersBar, DidomiPaginator, DidomiSkeleton, DidomiTable, DidomiTableBody, DidomiTableHeaderRow, DidomiTableHeading, DidomiTableTh } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { SvlVendorMatchingTableRow, SvlNoSuggestion } from '@components';
import { usePaginatedFilteredItems, useTableState, useVendors } from '@hooks';
import { VendorsSortableField, Vendor } from '@types';
import { filterVendors, mapVendorsBasedOnIab, filterDuplicateVendorsByNote } from '@utils';

const SEARCH_FIELDS = [{ field: 'name' }];
const vendorTableColHeaderName = [
  { name: 'Vendor name', sortId: 'nameOrKey' },
  { name: 'API ID', sortId: 'id' },
  { name: 'IAB/SDK ID', sortId: 'sdk_id' },
  { name: 'Vendor type', sortId: null },
  { name: 'Purposes (consent)', sortId: null },
  { name: 'Purposes (legitimate interest)', sortId: null },
  { name: 'Action', sortId: null },
];

const SvlVendorMatchingPage = () => {
  const { data: proposedVendors = [], isLoading } = useVendors();
  const { organizationId } = useActiveOrganization();

  const [showAllVendors, setShowAllVendors] = useState(true);

  const suggestedVendors = [];

  const vendors = useMemo(() => {
    return filterDuplicateVendorsByNote(mapVendorsBasedOnIab({ vendorsFromReportIds: proposedVendors }));
  }, [proposedVendors]);
  const filters = useMemo(() => {
    return [];
  }, []);
  const aggregatedVendorTableData = useMemo(() => {
    return filterVendors(vendors, organizationId);
  }, [organizationId, vendors]);

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
    sortConfig,
  } = useTableState<VendorsSortableField>({
    defaultLimit: 5,
    defaultFilters: {},
  });
  const { data: filteredVendors, total: vendorsTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableField>(aggregatedVendorTableData, {
    search,
    searchFields: SEARCH_FIELDS,
    limit: limit,
    page: currPage,
    filters,
    sortConfig,
  });
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);
  return (
    <div className="pt-l mb-l">
      {suggestedVendors.length || showAllVendors ? (
        <DidomiSkeleton isLoading={isLoading} variant="layout">
          <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
            <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
              <div className="text-primary-blue-6 font-semibold">
                {vendorsTotal === aggregatedVendorTableData.length
                  ? `${aggregatedVendorTableData.length} vendors`
                  : `${vendorsTotal} / ${aggregatedVendorTableData.length} vendors
          `}
              </div>
            </div>
            <DidomiFiltersBar
              data-skeleton
              data-testid="filter-bar-property"
              id="didomi-filters-without-default-value-property"
              defaultValue=""
              filters={{}}
              showSearch={true}
              onSearchTextChange={handleSearchChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={setSearch}
              collapsibleFilters={false}
            ></DidomiFiltersBar>
          </section>
          <DidomiTable
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={changeSorting}
            sortable={true}
            minWidth={1250}
            data-skeleton
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                {vendorTableColHeaderName.map((obj, idx) => (
                  <DidomiTableTh key={idx} sortId={obj.sortId} className="text-uppercase">
                    {obj.name.toUpperCase()}
                  </DidomiTableTh>
                ))}
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody>
              <SvlVendorMatchingTableRow isLoading={false} vendors={filteredVendors} />
            </DidomiTableBody>
          </DidomiTable>
          <DidomiPaginator
            data-skeleton
            data-testid="vendors-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={vendorsTotal}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </DidomiSkeleton>
      ) : (
        <SvlNoSuggestion setShowAllVendors={setShowAllVendors} />
      )}
    </div>
  );
};

export { SvlVendorMatchingPage };
