export const handleExistingVendorUpdate = async ({ requestBody, editVendor, vendor, redirectBack }) => {
  requestBody.last_metadata_partner_event_type = 'io.didomi.metadata.partner_updated';
  await editVendor({ ...requestBody, id: vendor.id });
  redirectBack();
};

export const handleNewVendorCreation = async ({ requestBody, vendorKey, vendor, createVendor, redirectBack }) => {
  requestBody.hosts = [vendorKey];
  requestBody.notes = `Replica of built-in Didomi vendor ${vendor.name} (${vendor.id})`;
  requestBody.namespaces = null;
  requestBody.last_metadata_partner_event_type = 'io.didomi.metadata.partner_created';
  await createVendor({ ...requestBody });
  redirectBack();
};
export const typeOfVendor = vendor => {
  if (!vendor.organization_id && vendor.namespaces?.iab2 > 0) return 'IAB';
  if (!vendor.organization_id && vendor.namespaces?.google > 0) return 'ATP';
  if (!vendor.organization_id && !vendor.namespaces?.iab2 && !vendor.namespaces?.iab && !vendor.namespaces?.google && !vendor.namespaces?.custom) return 'Model';
  return 'Custom';
};

export const ellipsis = (text, length) => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  } else {
    return text;
  }
};
export const handlePurposesText = purposes => {
  const purposeArray = purposes.split(',');
  return {
    title: ellipsis(purposeArray[0], 12),
    items: purposeArray,
  };
};
