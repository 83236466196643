import { AxiosError } from 'axios';

import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNoticeTemplateConfig, PaginatedResponse } from '@types';

/**
 * Fetches a particular consent notice config
 * */
export const useConsentNoticeTemplateConfig = (templateId: string, options?: UseQueryOptions<ConsentNoticeTemplateConfig>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchConsentNoticeTemplateConfig = async (): Promise<ConsentNoticeTemplateConfig> => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeTemplateConfig>>(
        `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/configs?template_id=${templateId}&$translations=true`,
      );

      return response.data?.data?.[0];
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useQuery<ConsentNoticeTemplateConfig, AxiosError>(['consent-notice-template-config', templateId], fetchConsentNoticeTemplateConfig, {
    enabled: templateId !== 'new',
    ...options,
  });
};
