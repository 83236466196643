import { useAuthToken, useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { Report, PaginatedResponse, RootProperty } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useReportFetchingLoop = report_id => {
  const queryClient = useQueryClient();
  const { organizationId } = useActiveOrganization();

  const axiosWithInterceptors = useAxiosWithLogOut({ skipLogoutOnForbidden: true });
  const token = useAuthToken();
  const fetchReportById = async (): Promise<Report> => {
    if (!report_id) return;
    try {
      const response = await axiosWithInterceptors.get<Report>(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/compliances/${report_id}`, {
        params: {
          organization_id: organizationId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  return useInfiniteQuery<Report, AxiosError>(['report_loop', report_id], fetchReportById, {
    onSuccess: data => {
      const reportData = data?.pages[data?.pages.length - 1];
      if (reportData) {
        queryClient.setQueryData(['properties', organizationId], (propsData: PaginatedResponse<RootProperty>) => {
          const reports = propsData.data.find(elm => elm.id === reportData.property_id)?.reports?.length || 0;
          const prop = propsData.data.find(elm => elm.id === reportData.property_id);
          const isNewReportInPropertyReports = prop?.reports?.find(elm => elm.id === reportData.id);
          if (!isNewReportInPropertyReports) {
            // If there are 2 reports just replace the last one
            reports === 2 ? (prop.reports[0] = reportData) : prop.reports.unshift(reportData);
          }
          return propsData;
        });
        queryClient.setQueryData(['property'], () => {
          return queryClient.invalidateQueries({ queryKey: ['property'] });
        });
        if (reportData.status === 'analyzed') {
          queryClient.invalidateQueries({ queryKey: ['properties', organizationId] });
          queryClient.invalidateQueries({ queryKey: ['report'] });
          queryClient.invalidateQueries({ queryKey: ['property'] });
        }
      }
    },
  });
};
