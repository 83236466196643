import { useState, useEffect } from 'react';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { SortConfig } from '@types';

type PaginateAndSearchOptions<T> = {
  search?: string;
  searchFields?: { field: string; transform?: (value: string) => string }[];
  translatableSearchFields?: string[];
  limit: number;
  page: number;
  filters?: Array<any>;
  sortConfig: SortConfig<T>;
};

const createSearchPurposesWorker = createWorkerFactory(() => import('./../workers'));

export const usePaginatedFilteredItems = <T, Z>(items: T[], options: PaginateAndSearchOptions<Z>) => {
  const [displayItems, setDisplayItems] = useState<T[]>([]);
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const [paginationTotal, setPaginationTotal] = useState<number>(10);
  const searchItemsWorker = useWorker(createSearchPurposesWorker);
  const { search, searchFields, translatableSearchFields, filters, page, limit, sortConfig } = options;

  useEffect(() => {
    if (!items?.length) return;

    (async () => {
      const { data, total, totalItems } = (await searchItemsWorker.paginateAndSearchItems(items, {
        search: search || '',
        searchFields: searchFields || [],
        translatableSearchFields,
        page,
        limit,
        sortConfig,
        filters,
      })) as { data: T[]; total: number; totalItems: T[] };
      setDisplayItems(data);
      setPaginationTotal(total);
      setFilteredItems(totalItems);
    })();
  }, [searchItemsWorker, items, search, searchFields, translatableSearchFields, page, limit, sortConfig, filters]);

  return {
    data: displayItems,
    total: paginationTotal,
    totalItems: filteredItems,
  };
};
