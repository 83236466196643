import React from 'react';
import { DidomiLanguageFlag, DidomiTableRow, DidomiTableTd } from '@didomi/ui-atoms-react';
import { SvlPropertyReportDateAndStatusTd, SvlPropertyTableActionTd } from '@components';
import { RootProperty } from '@types';
import { COUNTRY_CELL_DATA_PER_COUNTRY_CODE } from '@utils';

interface SvlPropertyTableRowProps {
  properties: RootProperty[];
  isLoading?: boolean;
  hasComplianceReportEditAccess: boolean;
}
const SvlPropertyTableRow = ({ properties = [], hasComplianceReportEditAccess }: SvlPropertyTableRowProps) => {
  const screenSize = window.innerWidth;
  return (
    <div>
      {properties.map(property => {
        return (
          <DidomiTableRow key={property.id} data-skeleton selectionValue={property}>
            <DidomiTableTd style={{ flex: '0 0 200px' }}>
              <td>
                <strong>{property.name}</strong>
              </td>

              <td>{property.website || '(no website)'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td className="flex items-center gap-xxs">
                <DidomiLanguageFlag language={COUNTRY_CELL_DATA_PER_COUNTRY_CODE[property.country_code]?.icon} style={{ '--icon-size': '15px' }} />
                {COUNTRY_CELL_DATA_PER_COUNTRY_CODE[property.country_code]?.label}
              </td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.tcfv2_vendors_count}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property?.gatp_vendors_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property?.custom_vendors_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <td>{property.unmatched_slds_count || '-'}</td>
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: '0 0 280px' }}>
              <SvlPropertyReportDateAndStatusTd property={property} rowKey={property.id} />
            </DidomiTableTd>
            <DidomiTableTd style={{ flex: screenSize > 1450 ? '1' : '0 0 140px' }}>
              <SvlPropertyTableActionTd domain={property} menuKey={property.id} hasComplianceReportEditAccess={hasComplianceReportEditAccess} />
            </DidomiTableTd>
          </DidomiTableRow>
        );
      })}
    </div>
  );
};

export { SvlPropertyTableRow };
