import { AccessTypes, AvailableModules, modules } from '@didomi/authorization-metadata';
import { useHasAccessPolicies } from '@didomi/utility-react';

export const useAccessPolicies = () => {
  const { hasAccess: hasComplianceReportViewAccess, isLoading: isLoadingComplianceReportViewAccess } = useHasAccessPolicies(
    modules[AvailableModules.ComplianceReport].accessTypeAllowedPolicies[AccessTypes.Viewer],
  );
  const { hasAccess: hasComplianceReportEditAccess, isLoading: isLoadingComplianceReportEditAccess } = useHasAccessPolicies(
    modules[AvailableModules.ComplianceReport].accessTypeAllowedPolicies[AccessTypes.Editor],
  );

  return {
    hasComplianceReportViewAccess,
    hasComplianceReportEditAccess,
    isLoading: isLoadingComplianceReportViewAccess || isLoadingComplianceReportEditAccess,
  };
};
