/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback } from 'react';
import { DidomiTabs, DidomiTabHeaders, DidomiTabPanels, DidomiTabHeader, DidomiTabPanel, DidomiTabHeading, DidomiLoader } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';

import { useLocalStorage } from 'usehooks-ts';
import { SvlPropertiesList, SvlVendorCardSelection, SvlVendorsList, SvlEmptyData } from '@components';
import { useProperty, useAccessPolicies } from '@hooks';
import { RootProperty } from '@types';
import { isDateOlderThanOneDay } from '@utils';

interface ComponentProps {
  activeStep?: number;
  setNextEnabled?: Function;
  setActiveStep?: Function;
  selectedProperties?: RootProperty[];
  setSelectedProperties?: Function;
  setAddVendorsConfigs?: Function;
}
const SvlSelectionPage = ({ setAddVendorsConfigs, activeStep, setNextEnabled, setActiveStep, selectedProperties, setSelectedProperties }: ComponentProps) => {
  const { data: { data: listProperties } = {}, isLoading: isLoadingProperties, error } = useProperty({ withReports: true });
  const { organizationId } = useActiveOrganization();
  const [storedSelectedProperties, setStoredSelectedProperties] = useLocalStorage<any>('svl-selected-properties' + organizationId, {});

  const { hasComplianceReportEditAccess, isLoading: isLoadingAccessPolicies } = useAccessPolicies();
  const isLoading = isLoadingProperties || isLoadingAccessPolicies;
  const handleSetSelectedProperties = useCallback(
    properties => {
      setSelectedProperties(properties);
    },
    [setSelectedProperties],
  );

  useEffect(() => {
    if (activeStep === 1 && selectedProperties.length === 0 && storedSelectedProperties?.data?.length) {
      if (isDateOlderThanOneDay(storedSelectedProperties.ttl)) return setStoredSelectedProperties({ data: [], ttl: new Date() });
      handleSetSelectedProperties(storedSelectedProperties.data);
    }
  }, [activeStep, storedSelectedProperties, handleSetSelectedProperties, selectedProperties, setStoredSelectedProperties]);
  return (
    <div className="pt-m">
      {isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading...</div>
        </div>
      ) : (
        <div>
          {(listProperties && listProperties.length === 0) || !!error ? (
            <SvlEmptyData error={!!error} code={error} />
          ) : (
            <DidomiTabs active-tab={activeStep}>
              <DidomiTabHeading variant="flow">
                <DidomiTabHeaders data-skeleton>
                  <DidomiTabHeader
                    name="1. Choose from your domains"
                    onHeaderClicked={() => {
                      setActiveStep(0);
                    }}
                  ></DidomiTabHeader>
                  <DidomiTabHeader disabled={activeStep === 0} name="2. Confirm vendor selection"></DidomiTabHeader>
                </DidomiTabHeaders>
              </DidomiTabHeading>
              <DidomiTabPanels>
                <DidomiTabPanel style={{ paddingRight: '3px' }}>
                  {!isLoadingProperties && (
                    <SvlPropertiesList
                      hasComplianceReportEditAccess={hasComplianceReportEditAccess}
                      properties={listProperties}
                      setNextEnabled={setNextEnabled}
                      setSelectedProperties={handleSetSelectedProperties}
                    />
                  )}
                </DidomiTabPanel>
                <DidomiTabPanel style={{ paddingRight: '3px', 'overflow-x': 'visible' }}>
                  <section className="flex justify-betweeen">
                    <div className="w-[60%] px-l">
                      <SvlVendorsList properties={selectedProperties} activeStep={activeStep} setActiveStep={setActiveStep} />
                    </div>
                    <div className="w-[40%] pl-l">
                      <SvlVendorCardSelection setAddVendorsConfigs={setAddVendorsConfigs} properties={selectedProperties} activeStep={activeStep} />
                    </div>
                  </section>
                </DidomiTabPanel>
              </DidomiTabPanels>
            </DidomiTabs>
          )}
        </div>
      )}
    </div>
  );
};

export { SvlSelectionPage };
