export const isLastReportWasRunInPasthour = ({ domain, report, listProperties }) => {
  const propId = domain ? domain.id : report?.details_json?.property?.id;
  const prop = listProperties?.find(elm => elm?.id === propId);
  if (prop && prop?.reports && prop?.reports.length) {
    const HOUR = 1000 * 60 * 60;
    const lastRequestedReportDateTime = new Date(prop.reports[0].created);
    const nextAllowedReportRequestDateTime = lastRequestedReportDateTime.getTime() + HOUR;
    return Date.now() < nextAllowedReportRequestDateTime;
  }
  return false;
};

export const checkAnddisplayIfIsNewReportExploitable = ({ domain, listProperties, setNewReportId, report }) => {
  const propId = domain ? domain.id : report?.details_json?.property?.id;
  const prop = listProperties?.find(elm => elm?.id === propId);
  if (prop && prop?.reports && prop?.reports.length) {
    const propWithRunningReport = prop.reports.find(report => report.status !== 'analyzed');
    if (propWithRunningReport) {
      setNewReportId(propWithRunningReport.id);
    }
  }
};

export const fetchPendingReportUntilBeeingExploitable = ({ reportResp, fetchNextPage, newReportId }) => {
  const reportData = reportResp?.pages[reportResp?.pages.length - 1];
  let timer1 = undefined;
  const endStatus = ['analyzed', 'failure'];
  if (reportData && !endStatus.includes(reportData.status)) {
    timer1 = setTimeout(() => fetchNextPage({ pageParam: newReportId }), 10000);
  } else {
    return clearTimeout(timer1);
  }
};
