import React, { useState, useEffect, useMemo } from 'react';
import {
  DidomiCardContainer,
  DidomiCheckbox,
  DidomiCollapse,
  DidomiIcon,
  DidomiRadioGroup,
  DidomiRadio,
  DidomiSkeleton,
  DidomiTooltip,
  DidomiIconButton,
} from '@didomi/ui-atoms-react';
import { usePropertyReportVendors } from '@hooks';
import { RootProperty } from '@types';
import { lastExploitableReport, defineVendorType, mapVendorsBaseFields, mapAndSetExploitableReportsIds } from '@utils';

interface SvlVendorCardSelectionProps {
  properties: RootProperty[];
  isLoading?: boolean;
  activeStep: Number;
  setAddVendorsConfigs?: Function;
}
const SvlVendorCardSelection = ({ setAddVendorsConfigs, properties = [], activeStep }: SvlVendorCardSelectionProps) => {
  const [reportIds, setReportIds] = useState(null);
  const { isLoading: vendorsLoading, data: vendorsFromApi } = usePropertyReportVendors({ ids: reportIds });

  const vendors = useMemo(() => {
    return mapVendorsBaseFields({ vendorsFromApi, defineVendorType });
  }, [vendorsFromApi]);
  useEffect(() => {
    return mapAndSetExploitableReportsIds({ properties, lastExploitableReport, activeStep, setReportIds });
  }, [properties, activeStep]);

  const [selectionExpended, setSelectionExpended] = useState(true);
  const [selectionExpended2, setSelectionExpended2] = useState(true);
  const [radioValue, setRadioValue] = useState('merge');
  const [addedVendors, setAddVendors] = useState(['iab']);
  const [checkedIab, setCheckedIab] = useState(true);
  const [checkedCustom, setCheckedCustom] = useState(true);
  const [checkedAtp, setCheckedAtp] = useState(true);
  const handleRadioChange = evt => {
    setRadioValue(evt.detail);
  };

  useEffect(() => {
    let selectedVendors = [];

    if (checkedIab) selectedVendors.push('iab');
    if (checkedCustom) selectedVendors.push('custom');
    if (checkedAtp) selectedVendors.push('atp');

    setAddVendors(selectedVendors);
  }, [checkedIab, checkedCustom, checkedAtp, setAddVendors]);

  useEffect(() => {
    setAddVendorsConfigs({
      addMethod: radioValue,
      addedVendors: addedVendors,
    });
  }, [radioValue, setAddVendorsConfigs, addedVendors]);

  return (
    <>
      <div className="flex w-full justify-end pt-l">
        <DidomiSkeleton variant="layout" isLoading={vendorsLoading}>
          <DidomiCardContainer
            style={{ '--card-inner-padding': '24px', '--card-outer-bg': 'transparent', '--card-outer-padding': '0px', width: '432px' }}
            className="shadow-2xl mr-[30px]"
          >
            <p data-skeleton className="text-primary-blue-6 text-h3 font-bold font-serif">
              Vendor selection options
            </p>
            <div data-skeleton className="h-[1px] w-[full] bg-neutral-gray-3 mt-[16px] mb-[24px]"></div>
            <div data-skeleton className="flex justify-between">
              <p data-skeleton className="body-normal-semibold text-primary-pink-5 mb-s">
                Type of vendor:
              </p>
              <DidomiIconButton
                data-testid={`toggle-collapse`}
                variant="option"
                icon={selectionExpended ? 'up-on-sm' : 'down-on-sm'}
                onClick={() => setSelectionExpended(!selectionExpended)}
              />
            </div>
            <DidomiCollapse id="demo-collapse" is-expanded={selectionExpended.toString()} style={{ '--collapse-duration': '200ms', '--collapse-easing': 'ease-in' }}>
              <div data-skeleton>
                <div className="flex">
                  <DidomiCheckbox
                    checked={checkedIab}
                    onValueChange={() => setCheckedIab(!checkedIab)}
                    className="mr-xxs"
                    label={`IAB (${vendors?.filter(vendor => vendor.filterType === 'iab').length || 0})`}
                  />
                  <DidomiTooltip content={'The vendors part of the IAB Global Vendor List, which is managed by IAB.'} variant="helper" placement="top">
                    <DidomiIcon className="ml-xxs" name="helper-text" />
                  </DidomiTooltip>
                </div>
                <div className="flex" data-skeleton>
                  <DidomiCheckbox
                    className="mr-xxs"
                    checked={checkedAtp}
                    onValueChange={() => setCheckedAtp(!checkedAtp)}
                    label={`Google ATP (${vendors?.filter(vendor => vendor.filterType === 'atp').length || 0})`}
                  />
                  <DidomiTooltip content={'The vendors part of the Google ATP vendor list, which is managed by Google.'} variant="helper" placement="top">
                    <DidomiIcon className="ml-xxs" name="helper-text" />
                  </DidomiTooltip>
                </div>
                <div className="flex" data-skeleton>
                  <DidomiCheckbox
                    className="mr-xxs"
                    checked={checkedCustom}
                    onValueChange={() => setCheckedCustom(!checkedCustom)}
                    label={`Custom (${vendors?.filter(vendor => vendor.filterType === 'custom').length || 0})`}
                  />
                  <DidomiTooltip content={'The vendors created and managed by your organisation in the Data Manager module.'} variant="helper" placement="top">
                    <DidomiIcon className="ml-xxs" name="helper-text" />
                  </DidomiTooltip>
                </div>
              </div>
              <div data-skeleton className="h-[1px] w-[full] bg-neutral-gray-3 mt-[16px] mb-[24px]"></div>
            </DidomiCollapse>
            <div data-skeleton className="flex justify-between">
              <p data-skeleton className="body-normal-semibold text-primary-pink-5 mb-s">
                Keep previous selection?
              </p>
              <DidomiIconButton
                data-testid={`toggle-collapse`}
                variant="option"
                icon={selectionExpended2 ? 'up-on-sm' : 'down-on-sm'}
                onClick={() => setSelectionExpended2(!selectionExpended2)}
              />
            </div>
            <DidomiCollapse id="demo-collapse" is-expanded={selectionExpended2.toString()} style={{ '--collapse-duration': '200ms', '--collapse-easing': 'ease-in' }}>
              <div>
                <div className="flex">
                  <DidomiRadioGroup value={radioValue} onValueChange={handleRadioChange} orientation="vertical">
                    <DidomiRadio data-skeleton value="merge" style={{ marginBottom: '8px' }}>
                      <span style={{ fontSize: '14px', fontWeight: 400 }} className="primary-blue-6">
                        Yes, merge with new vendors
                      </span>
                    </DidomiRadio>
                    <DidomiRadio data-skeleton value="overwrite">
                      <span style={{ fontSize: '14px', fontWeight: 400 }} className="primary-blue-6">
                        No, replace all
                      </span>
                    </DidomiRadio>
                  </DidomiRadioGroup>
                </div>
              </div>
            </DidomiCollapse>
          </DidomiCardContainer>
        </DidomiSkeleton>
      </div>
    </>
  );
};

export { SvlVendorCardSelection };
