export const handleRowSelectionAndSelectAll = ({ evt, setSelectedProperties, setSelectedpropertiesRef, setStoredSelectedProperties, totalFilteredItems, templateId }) => {
  if (evt.type === 'toggleAllRowsSelectedChange') {
    if (evt.detail.selectedItems.length) {
      setStoredSelectedProperties({ templateId: templateId, data: totalFilteredItems, ttl: new Date() });
      setSelectedpropertiesRef(totalFilteredItems);
      setSelectedProperties(totalFilteredItems);
    } else {
      setStoredSelectedProperties({ templateId: templateId, data: [], ttl: new Date() });
      setSelectedpropertiesRef([]);
      setSelectedProperties([]);
    }
  } else {
    setStoredSelectedProperties({ templateId: templateId, data: evt.detail.newSelectedItems, ttl: new Date() });
    setSelectedpropertiesRef(evt.detail.newSelectedItems);
    setSelectedProperties(evt.detail.newSelectedItems);
  }
};

export const handlePropertySelectionBasedOnStorage = ({ selectedpropertiesRef, storedSelectedProperties, isDateOlderThanOneDay, setStoredSelectedProperties, handleSelection }) => {
  if (selectedpropertiesRef.length === 0 && storedSelectedProperties.data && storedSelectedProperties.data.length) {
    if (isDateOlderThanOneDay(storedSelectedProperties.ttl)) return setStoredSelectedProperties({ noticeId: '', data: [], ttl: new Date() });
    handleSelection({ detail: { newSelectedItems: storedSelectedProperties.data } });
  }
};
