/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { DidomiIconButton, DidomiSkeleton, DidomiMenu, DidomiMenuItem, DidomiButton, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';
import { useGeneratePropertyReport, useProperty, useReportFetchingLoop } from '@hooks';
import { Report, RootProperty } from '@types';
import { isLastReportWasRunInPasthour, checkAnddisplayIfIsNewReportExploitable, fetchPendingReportUntilBeeingExploitable, lastExploitableReport } from '@utils';

interface SvlPropertyTableActionTdProps {
  report?: Report;
  menuKey: number | string;
  hasComplianceReportEditAccess: boolean;
  domain?: RootProperty;
  hideRemove?: boolean;
}
const SvlPropertyTableActionTd = ({ menuKey, hasComplianceReportEditAccess, domain }: SvlPropertyTableActionTdProps) => {
  const { data: { data: listProperties } = {} } = useProperty({ withReports: true });
  const [newReportId, setNewReportId] = useState('');
  const { data: reportResp, fetchNextPage } = useReportFetchingLoop(newReportId);
  const { mutateAsync: askNewReportForProperty, data: generateReportData } = useGeneratePropertyReport({});
  const [textModal, setTextModal] = useState('new');
  const [isOpen, setIsOpen] = useState(false);

  const report = useMemo(() => {
    return lastExploitableReport(domain);
  }, [domain, lastExploitableReport]);

  /* istanbul ignore next */
  const handleDialogChange = (e: CustomEvent) => {
    // Update if dialog closed from escape or outside click
    if (isOpen && !e.detail) {
      setIsOpen(e.detail);
    }
  };
  /* istanbul ignore next */
  const openDialog = modal => {
    setTextModal(modal);
    setIsOpen(true);
  };
  /* istanbul ignore next */
  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleNewReportDemand = () => {
    if (!domain) {
      const mappedDomain = listProperties.find(elm => elm.id === report.details_json.property.id);
      askNewReportForProperty(mappedDomain);
    } else {
      askNewReportForProperty(domain);
    }

    closeDialog();
  };

  useEffect(() => {
    if (generateReportData?.data.id) {
      setNewReportId(generateReportData.data.id);
    }
  }, [generateReportData]);

  const scrapperCooldownActive = useMemo(() => {
    return isLastReportWasRunInPasthour({ report, domain, listProperties });
  }, [report, domain, listProperties, reportResp]);

  useEffect(() => {
    return checkAnddisplayIfIsNewReportExploitable({ domain, listProperties, report, setNewReportId });
  }, [domain, listProperties, report, fetchNextPage]);

  useEffect(() => {
    return fetchPendingReportUntilBeeingExploitable({ reportResp, fetchNextPage, newReportId });
  }, [reportResp, fetchNextPage, newReportId]);

  return (
    <div style={{ width: '100px' }}>
      <DidomiSkeleton variant="layout">
        <DidomiIconButton id={`menu-button-${menuKey}`} variant="rounded" icon="submenu" data-skeleton data-testid="menu-action-button"></DidomiIconButton>
        <DidomiMenu for={`menu-button-${menuKey}`} variant="main" data-skeleton placement="left-start">
          {hasComplianceReportEditAccess && (
            <>
              <DidomiMenuItem icon-name="new-create" onClick={() => openDialog('new')} data-cy="request-report">
                Request new report
              </DidomiMenuItem>
            </>
          )}
        </DidomiMenu>
        <DidomiModal isOpen={isOpen} onOpenChange={handleDialogChange} attachTo="overlay-container-test-app" returnFocusAfterClose={false}>
          <DidomiModalHeader
            illustrationName="warning-modal"
            modalTitle={
              textModal === 'new' ? (scrapperCooldownActive ? 'You can not run a new scan' : 'You are going to launch a new report.') : 'You are going to remove a domain.'
            }
            modalSubTitle="Pay attention"
            modalDescription={
              textModal === 'new'
                ? scrapperCooldownActive
                  ? 'Your last report was launched less than an hour ago, you must wait at least an hour before launching a new one'
                  : 'The request will be processed in some time.'
                : ''
            }
            variant="warning"
          ></DidomiModalHeader>

          <DidomiModalActions>
            <DidomiButton variant="secondary" onClick={closeDialog}>
              Close
            </DidomiButton>
            <DidomiButton
              style={{ display: scrapperCooldownActive && textModal === 'new' ? 'none' : 'inline-block' }}
              onClick={handleNewReportDemand}
              className="min-w-[215px]"
              data-cy="accept-btn"
            >
              Confirm the request
            </DidomiButton>
          </DidomiModalActions>
        </DidomiModal>
      </DidomiSkeleton>
    </div>
  );
};

export { SvlPropertyTableActionTd };
