export const mapVendorsBaseFields = ({ vendorsFromApi, defineVendorType }) => {
  if (!vendorsFromApi) return [];
  return vendorsFromApi.map(vendor => {
    const baseFields = {
      filterType: defineVendorType(vendor),
      nameOrKey: vendor.name || vendor.key,
    };
    return {
      ...vendor,
      ...baseFields,
    };
  });
};

export const mapAndSetExploitableReportsIds = ({ properties, lastExploitableReport, activeStep, setReportIds }) => {
  const arrayOfExploitableReports = properties.map(property => {
    return lastExploitableReport(property);
  });
  const listOfExploitableIds = arrayOfExploitableReports.map(report => {
    if (!report?.id) return null;
    return report?.id;
  });
  activeStep === 0 ? setReportIds(null) : setReportIds(() => [...listOfExploitableIds.filter(elm => elm)]);
};

export const mapVendorsBasedOnIab = ({ vendorsFromReportIds }) => {
  let vnd = vendorsFromReportIds;
  const iabVnds = vnd?.filter(elm => elm.vendor_iabv2_id) || [];
  const nonIabVnds = vnd?.filter(elm => !elm.vendor_iabv2_id) || [];
  const arr = [...iabVnds];
  nonIabVnds?.length && nonIabVnds.forEach(vendor => arr.push(vendor));
  return arr;
};

export const filterDuplicateVendorsByNote = array => {
  // Create a set to store canonical_partner_ids found in notes
  let idsInNotes = new Set();
  // Regular expression to match canonical_partner_id in the note
  let regex = /\(([^)]+)\)/;
  // Iterate over the array and extract canonical_partner_ids from notes
  array.forEach(element => {
    let notes = element.notes || '';
    let match = notes.match(regex);
    if (match) {
      let id = match[1];
      idsInNotes.add(id);
    }
  });
  // Filter the array to remove elements whose id is in idsInNotes
  let filteredArray = array.filter(element => !idsInNotes.has(element.id));

  return filteredArray;
};
