import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNotice, ConsentNoticeTemplateConfig, ConsentNoticeTemplate } from '@types';
import { sanitizeObject } from '@utils';

/**
 * Edits a template config, makes sure to update the list of templates with the edited template value
 * */
export const useEditConsentNoticeTemplateConfig = (options?: UseMutationOptions<AxiosResponse<ConsentNoticeTemplateConfig>, AxiosError, ConsentNoticeTemplateConfig>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  return useMutation(
    async (templateConfig: ConsentNoticeTemplateConfig) => {
      const noticeConfigBody = sanitizeObject(templateConfig);
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/configs/${templateConfig?.id}?$translations=true`, noticeConfigBody);
    },
    {
      ...options,
      onSuccess: (editedTemplateConfigReponse: AxiosResponse<ConsentNoticeTemplateConfig>, variables, context) => {
        const editedTemplateConfig = editedTemplateConfigReponse.data;
        const templateConfigId = editedTemplateConfig.id;
        const templateId = editedTemplateConfig.template_id;

        const templateGet: PaginatedResponse<ConsentNoticeTemplate> = queryClient.getQueryData(['consent-notice-template', templateId]);
        /* istanbul ignore if */
        if (templateGet) {
          queryClient.setQueryData(['consent-notices-template', templateId], {
            ...templateGet,
            config: editedTemplateConfig.config,
          });
        }

        const consentNoticeConfigGet: ConsentNotice = queryClient.getQueryData(['consent-notice-template-config', templateConfigId]);

        /* istanbul ignore if */
        if (consentNoticeConfigGet) {
          queryClient.setQueryData(['consent-notice-template-config', templateConfigId], { ...consentNoticeConfigGet, ...editedTemplateConfig });
        }

        if (options?.onSuccess) {
          options.onSuccess(editedTemplateConfigReponse, variables, context);
        }
      },
    },
  );
};
