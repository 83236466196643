import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import React, { cloneElement } from 'react';

import { DidomiSecondaryHeader, DidomiBackButtonLink, DidomiHintbox, DidomiSkeleton, DidomiButton } from '@didomi/ui-atoms-react';
import { useSPARouter, useActiveOrganization } from '@didomi/utility-react';

interface SvlMatchingVendorLayoutProps {
  children: any;
  loading?: boolean;
}
/**
 * Main Layout
 * Default layout with header
 */
export const SvlMatchingVendorLayout = ({ children }: SvlMatchingVendorLayoutProps): JSX.Element => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();
  const { organizationId } = useActiveOrganization();

  const vendorKey = searchParams.get('vendorKey');
  const { templateId } = useParams();

  const handleBackButton = () => {
    const location = window.location.pathname;
    navigate(`${location.replace(`/${organizationId}/smart-vendor-list/assign-domain`, '')}?activeTab=vendor`);
  };

  const getDomainFromUrl = (url: string): string | null => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    } catch (error) {
      return null;
    }
  };

  const handleCreateNewVendor = () => {
    navigateTo(`/data-manager/add-vendor?vendorKey=${vendorKey}&templateId=${templateId}&fromPath=${window.location.pathname + window.location.search}`);
  };

  const vendorDomain = getDomainFromUrl(vendorKey);

  return (
    <section className=" h-full box-border !flex flex-col">
      <DidomiSkeleton variant="layout" isLoading={false}>
        <div>
          <DidomiSecondaryHeader title-text={`Matching: ${vendorKey} ${vendorDomain || ''}`} actions-in-center>
            <div slot="back-button">
              <DidomiBackButtonLink data-testid="back-btn" className="cursor-pointer" onClick={handleBackButton}></DidomiBackButtonLink>
            </div>
            <div slot="actions">
              <DidomiButton variant="secondary" onClick={handleCreateNewVendor}>
                Create new vendor
              </DidomiButton>
            </div>
          </DidomiSecondaryHeader>
          <DidomiHintbox title-text="" className="mt-s">
            <span className="font-semibold text-primary-blue-6" style={{ fontSize: '16px' }}>
              Match <span className="px-xxs font-semibold italic text-primary-blue-4">{vendorKey}</span> to one of the vendors listed below or create a new vendor.
            </span>
          </DidomiHintbox>
        </div>
        {cloneElement(children)}
      </DidomiSkeleton>
    </section>
  );
};
