export type VendorsSortableField = 'nameOrKey' | 'vendor_iabv2_id';

export type VendorLink = {
  website: string;
  privacypolicy: string;
  optout: string;
  terms: string;
  dpa: string;
  protection: string;
  subprocessors: string;
};

export type VendorLinkReadable = {
  label: string;
  value: string;
};

export enum VendorLinkType {
  dpa = 'Data Processing',
  optout = 'Opt-out',
  privacypolicy = 'Privacy Policy',
  protection = 'Applicable protection',
  subprocessors = 'List of Subconstructors',
  terms = 'Terms & Conditions',
  website = 'Website',
}
export type CollectedVendors = {
  canonical_metadata_partner_id?: string | null;
  canonical_metadata_partner_name?: string | null;
  functional_namespace?: string | null;
  id?: string | null;
  key?: string | null;
  name?: string | null;
  namespaces?: null | string;
  notes?: string | null;
  organization_id?: string;
  url_sld?: string | null;
  url_host: string | null;
  vendor_iabv2_id?: null | number;
  vendor_tcfv2_data?: null | number;
  nameOrKey?: string | null;
  url?: string;
};
export interface Vendor {
  vendor: any;
  url?: string;
  id?: string;
  name?: string | { [language: string]: string };
  website?: string;
  organization_id: string;
  is_initiated_by_property?: number;
  is_property?: number;
  key?: string;
  url_sld?: string;
  url_host: string;
  cookies?: any[];
  ntags?: number;
  initiators?: string[];
  security?: Security3;
  tcfv2_data_json?: Tcfv2_data_json;
  vendor_country_name?: string;
  vendor_country_protection?: string;
  vendor_iabv2_id?: number;
  vendor_location?: string;
  vendor_tcfv2_data?: null;
  vendor_vendorcategory_name?: string;
  vendor_vigilance_rating?: number;
  nameOrKey?: string | { [language: string]: string };
  iabTcfId?: number;
  sdk_id?: string | number;
  namespaces?:
    | VendorNamespaces
    | null
    | {
        iab?: string;
        iab2?: string;
        didomi?: string;
        custom?: string;
        google?: string;
      };
  deprecated?: boolean;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  spi_purposes_id?: string[];
  address?: { [language: string]: string } | string;
  country_id?: string;
  protection_id?: string;
  links?: VendorLink;
  links_readable?: VendorLinkReadable[];
  tags?: [string];
  contact?: string;
  website_optout?: string;
  hosts?: [string];
  url_patterns?: [string];
  urls?: [string];
  taxonomy_id?: string;
  category_id?: string;
  country?: string;
  created_at?: Date;
  updated_at?: Date;
  version?: number;
  default_purposes_name?: string[];
  legitimate_interest_purposes_name?: string[];
  cookie_max_duration?: number;
  cookie_max_duration_unit?: string;
  uses_non_cookie_access?: boolean;
  filterType?: string;
  notes?: string;
}
export interface VendorRequestBody {
  address?: { [language: string]: string };
  notes?: string;
  category_id?: string;
  contact?: { [language: string]: string };
  cookie_max_duration?: number;
  cookie_max_duration_unit?: string;
  country_id?: string;
  default_purposes_id?: string[];
  legitimate_interest_purposes_id?: string[];
  links?: {
    dpa?: string;
    optout?: string;
    privacypolicy: string;
    protection?: string;
    subprocessors?: string;
    terms?: string;
    website?: string;
  };
  hosts?: string[];
  name: string;
  namespaces?: { custom: string };
  organization_id: string;
  protection_id?: string;
  taxonomy_id?: string;
  uses_non_cookie_access?: boolean;
  spi_purposes_id?: string[];
  last_metadata_partner_event_time?: Date;
  last_metadata_partner_event_type?: 'io.didomi.metadata.partner_updated' | 'io.didomi.metadata.partner_created';
}

export type VendorStorage = {
  created_at: string;
  domain: string;
  id: string;
  identifier: string;
  max_age: number;
  max_age_unit: string;
  organization_id: string;
  partner_id: string;
  purposes_id: string[];
  type: string;
  updated_at: string;
  version: number;
};

export interface VendorStorageRequestBody {
  domain: string;
  identifier: string;
  max_age: number;
  max_age_unit: string;
  organization_id: string;
  partner_id?: string;
  purposes_id?: string[];
  type: string;
}

export interface VendorNamespaces {
  didomi?: string;
  custom?: string;
  iab?: number;
  iab2?: number;
  google?: number;
}

export interface vendorGraph {
  id: string;
  key: number | string;
  initiator_slds: string[];
  initiator_is_property: number;
  initiator_key: number | string;
  initiator_ntags: number;
  tcfv2_data_json: TcfData;
  name: string;
  website: string;
  cookies: any[];
  ntags: number;
  initiators: any[];
  security: Security3;
}
export interface TcfData {}
export interface TrustIndex {
  chipsColor: string | null;
  numeric: number | null;
  alpha: string | null;
}
export interface AggregatedVendor {
  id: string | number;
  name: string | null;
  trustIndex: TrustIndex;
  tcfId: number | null | string;
  legalBasis: string | null;
  country: string | null;
  countryAdequation: string | null;
  requests: number | null;
  tcfv2_data_json: Tcfv2_data_json;
}

export interface Tcfv2_data_json {
  cookieMaxAgeSeconds: number;
  cookieRefresh: boolean;
  deviceStorageDisclosureUrl: string;
  features: number[];
  flexiblePurposes: number[];
  id: number;
  legIntPurposes: number[];
  name: string;
  policyUrl: string;
  purposes: number[];
  specialFeatures: [];
  specialPurposes: number[];
  usesCookies: boolean;
  usesNonCookieAccess: boolean;
}
export interface Initiators {
  property: boolean;
  vendors: Vendor[];
}

export interface Security2 {
  ssl: number;
  protocols: number;
}

export interface Vendors {
  security: Security2;
}

export interface Security3 {
  protocols: Protocols;
  certificate: Certificate;
}

export interface Protocols {
  latest: boolean;
  forwardsecrecy: boolean;
}

export interface Certificate {
  valid: boolean;
  trusted: boolean;
}
