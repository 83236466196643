import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { MainLayout, SvlMatchingVendorLayout } from '@layouts';
import { SvlSelectionPage, SvlVendorMatchingPage } from '@pages';

const App = () => {
  const { organizationId } = useActiveOrganization();
  return (
    <BrowserRouter basename={`${organizationId}/smart-vendor-list`}>
      <Routes>
        <Route
          path="/:templateId"
          element={
            <div className="p-l">
              <MainLayout>
                <SvlSelectionPage />
              </MainLayout>
            </div>
          }
        ></Route>
        <Route
          path="/assign-domain/:templateId"
          element={
            <div className="p-l">
              <SvlMatchingVendorLayout>
                <SvlVendorMatchingPage />
              </SvlMatchingVendorLayout>
            </div>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export { App };
