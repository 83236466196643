import { sanitizeHTML } from '@didomi/xss/sanitizers';

/**
 * Generates a new object with a modified version that contains all strings sanitized.
 *
 * Example:
 *
 * const newObject = sanitizeObject(someObject)
 *
 * @param {object} obj The object to "clone" and sanitize
 * @returns {object} the sanitized object
 */
export const sanitizeObject = <T>(object: T): T => {
  const newObject = structuredClone(object);
  traverse(newObject, (val: string) => sanitizeHTML(val).result);
  return newObject;
};

/**
 * Applies a function to all string properties inside an option.
 * It works on nested properties as well.
 *
 * Example:
 *
 * const traverse(someObject, (value) => value.toUpperCase())
 *
 * @param {object} object The object to modify
 * @param {(value: string) => void} fn The function to apply to all string properties
 */
const traverse = (object: any, fn: (value: string) => void) => {
  for (const propName in object) {
    if (object[propName] !== null && typeof object[propName] === 'string') {
      object[propName] = fn(object[propName]);
    }
    if (object[propName] !== null && typeof object[propName] === 'object') {
      traverse(object[propName], fn);
    }
  }
};
