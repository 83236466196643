import { sortArrayByDate, formatDate } from './formatDate';
export const lastExploitableReport = domain => {
  if (!domain && !domain?.reports) return null;
  if (domain && !domain?.reports) return null;
  let lastExploitableReport = null;
  const reports = sortArrayByDate(domain.reports, 'modified');

  for (let i = 0; i < reports.length; i++) {
    if (reports[i].status === 'analyzed') {
      lastExploitableReport = reports[i];
      break; // This will exit the loop immediately when the condition is met
    }
  }
  return lastExploitableReport;
};

export const lastReportDate = property => {
  const isTwoReports = property?.reports?.length === 2;
  const hasAtLeasOneReport = !!property?.reports?.length;

  const mapStatus = (status: string) => {
    switch (status) {
      case 'analyzed':
        return 'ok';
      case 'failure':
        return 'ko';
      default:
        return 'pending';
    }
  };

  const firstReportStatus = () => {
    if (!hasAtLeasOneReport) return 'ko';
    return mapStatus(property.reports[0].status);
  };

  const secondtReportStatus = () => {
    if (!hasAtLeasOneReport || (hasAtLeasOneReport && !isTwoReports)) return 'ko';
    return mapStatus(property.reports[1].status);
  };

  return firstReportStatus() === 'ok' ? formatDate(property?.reports[0]?.modified) : secondtReportStatus() === 'ok' ? formatDate(property?.reports[1]?.modified) : '';
};
