import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiTableRow, DidomiTableTd, DidomiButton } from '@didomi/ui-atoms-react';

import { Vendor } from '@types';
interface SvlVendorTableRowProps {
  vendors: Vendor[];
  isLoading?: boolean;
}
const SvlVendorTableRow = ({ vendors = [] }: SvlVendorTableRowProps) => {
  const navigate = useNavigate();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/smart-vendor-list');
  const { templateId } = useParams();
  const redirectToMatching = vendor => {
    const nameOrKey = vendor.id ? vendor.name : vendor.url_host;
    navigate(`/assign-domain/${templateId}/?vendorKey=${nameOrKey}`);
  };

  return (
    <>
      {vendors.map((vendor: Vendor, idx) => {
        return (
          <>
            <DidomiTableRow key={idx} data-skeleton>
              <DidomiTableTd className={vendor.filterType === 'unmatched' ? 'text-neutral-gray-5' : ''}>
                <td>
                  <strong>{vendor.nameOrKey} </strong>
                </td>
              </DidomiTableTd>
              <DidomiTableTd className={vendor.vendor_iabv2_id ? '' : 'text-neutral-gray-5'}>
                {vendor?.filterType === 'custom' ? (
                  <td className="text-primary-blue-6">Custom</td>
                ) : vendor?.filterType === 'atp' ? (
                  <td className="text-primary-blue-6">
                    <img alt="atp logo" src={`${ASSETS_URL}/assets/svg/atp-logo.svg`} />
                  </td>
                ) : vendor?.filterType === 'iab' ? (
                  <td>
                    <img alt="iab logo" src={`${ASSETS_URL}/assets/svg/iab-logo.svg`} />
                  </td>
                ) : vendor?.filterType === 'unmatched' ? (
                  <td className="text-primary-pink-5">Unmatched</td>
                ) : (
                  <td></td>
                )}
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {!vendor?.id && (
                    <DidomiButton size="small" variant="secondary" onClick={() => redirectToMatching(vendor)}>
                      Find a match
                    </DidomiButton>
                  )}
                </td>
              </DidomiTableTd>
            </DidomiTableRow>
          </>
        );
      })}
    </>
  );
};

export { SvlVendorTableRow };
