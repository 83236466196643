import { useSearchParams, useParams } from 'react-router-dom';
import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton } from '@didomi/ui-atoms-react';

import { useSPARouter } from '@didomi/utility-react';

interface SvlNoSuggestionProps {
  setShowAllVendors: Function;
}
const SvlNoSuggestion = ({ setShowAllVendors }: SvlNoSuggestionProps) => {
  let [searchParams] = useSearchParams();
  const { navigateTo } = useSPARouter();

  const vendorKey = searchParams.get('vendorKey');
  const { templateId } = useParams();

  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/smart-vendor-list');
  const handleShowAllVendor = () => {
    setShowAllVendors(true);
  };
  const handleCreateNewVendor = () => {
    navigateTo(`/data-manager/match-vendor/${templateId}?vendorKey=${vendorKey}`);
  };
  return (
    <>
      <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
        <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
          <img alt="agnostik-logo" className="w-[220px] mt-[140px] mb-xs" src={`${ASSETS_URL}/assets/img/No-result-found.png`} />
          <span className="text-center text-primary-blue-4 mb-xxxs body-big-semibold">We have no suggestions for this match!</span>
          <span className="text-center text-primary-blue-6 mb-xxxs text-h3">You can manually look through all the vendors, or you can go for a new vendor.</span>
          <div className="flex justify-around mt-xs">
            <DidomiButton variant="secondary" className="mr-xs" onClick={handleShowAllVendor}>
              <span style={{ fontSize: '14px' }}>Show all vendors</span>
            </DidomiButton>
            <DidomiButton variant="secondary" onClick={handleCreateNewVendor}>
              Match it to new vendor
            </DidomiButton>
          </div>
        </div>
      </div>
    </>
  );
};

export { SvlNoSuggestion };
