import React from 'react';
import { setUpDidomiUIAtoms } from '@didomi/ui-atoms-react';
import { Standalone } from '@didomi/utility';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Root } from './root.component';

// Entry point for Single SPA
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const { bootstrap, mount: mountOriginal, unmount } = lifecycles;

async function mount(opts) {
  // If we run the app as standalone, we init the app
  // eslint-disable-next-line no-undef
  if (CONFIG.standalone) {
    await Standalone.initApp();
  }
  await setUpDidomiUIAtoms('smart-vendor-list');
  mountOriginal(opts);
}

export { bootstrap, mount, unmount };
