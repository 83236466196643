import { useAuthToken, useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { OrganizationProperty, Report } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useGeneratePropertyReport = (options: UseMutationOptions<AxiosResponse<Report>, AxiosError, Partial<OrganizationProperty>>) => {
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut({ skipLogoutOnForbidden: true });
  const { organizationId } = useActiveOrganization();

  return useMutation(
    async (option: Partial<OrganizationProperty>) => {
      const requestBody = {
        property_id: option.id,
        organization_id: option.organization_id,
      };
      //@todo: update endpoint
      return axiosWithInterceptors.post<Report>(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/compliances?organization_id=${organizationId}`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      ...options,
    },
  );
};
