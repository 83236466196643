export const getModalTextDependingOnSaveActions = ({ addVendorsConfig, updatedConfig, managedVendors }) => {
  const texts = {
    title: '',
    descMethod: '',
    desc: '',
    button: '',
  };
  if (addVendorsConfig?.addMethod === 'merge' && updatedConfig) {
    texts.title = `Merge ${managedVendors.length} vendors into your vendor list`;
    texts.descMethod = 'will be added';
    texts.desc = 'Unmatched vendors are excluded';
    texts.button = 'Merge & Save';
  }
  if (addVendorsConfig?.addMethod === 'overwrite' && updatedConfig) {
    texts.title = `Overwrite the vendor list
        with these ${managedVendors.length} vendors`;
    texts.descMethod = 'will replace';
    texts.desc = 'Unmatched vendors are excluded';
    texts.button = 'Overwrite & Save';
  }
  return texts;
};
