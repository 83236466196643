import { DataLanguage } from '@types';

// Copied from https://gitlab.com/didomi/console/-/blob/master/src/app/shared/services/i18n/fixtures/supported-languages.ts
// TODO: Move this to a utility/helper
export const supportedLanguages: DataLanguage[] = [
  { longName: 'Arabic', code: 'ar' },
  { longName: 'Azerbaijani', code: 'az' },
  { longName: 'Bengali', code: 'bn' },
  { longName: 'Brasilian', code: 'pt-BR' },
  { longName: 'Bulgarian', code: 'bg' },
  { longName: 'Catalan', code: 'ca' },
  { longName: 'Chinese Simplified', code: 'zh-CN' },
  { longName: 'Chinese Traditional', code: 'zh-TW' },
  { longName: 'Croatian', code: 'hr' },
  { longName: 'Czech', code: 'cs' },
  { longName: 'Danish', code: 'da' },
  { longName: 'Dutch', code: 'nl' },
  { longName: 'English', code: 'en' },
  { longName: 'Estonian', code: 'et' },
  { longName: 'Filipino', code: 'fil' },
  { longName: 'Finnish', code: 'fi' },
  { longName: 'French', code: 'fr' },
  { longName: 'German', code: 'de' },
  { longName: 'Greek', code: 'el' },
  { longName: 'Hebrew', code: 'he' },
  { longName: 'Hindi', code: 'hi' },
  { longName: 'Hungarian', code: 'hu' },
  { longName: 'Indonesian', code: 'id' },
  { longName: 'Italian', code: 'it' },
  { longName: 'Japanese', code: 'ja' },
  { longName: 'Korean', code: 'ko' },
  { longName: 'Latvian', code: 'lv' },
  { longName: 'Lithuanian', code: 'lt' },
  { longName: 'Macedonian', code: 'mk' },
  { longName: 'Malay', code: 'ms' },
  { longName: 'Norwegian', code: 'no' },
  { longName: 'Polish', code: 'pl' },
  { longName: 'Portuguese', code: 'pt' },
  { longName: 'Romanian', code: 'ro' },
  { longName: 'Russian', code: 'ru' },
  { longName: 'Serbian', code: 'sr' },
  { longName: 'Slovak', code: 'sk' },
  { longName: 'Slovenian', code: 'sl' },
  { longName: 'Spanish', code: 'es' },
  { longName: 'Swahili', code: 'sw' },
  { longName: 'Swedish', code: 'sv' },
  { longName: 'Thai', code: 'th' },
  { longName: 'Turkish', code: 'tr' },
  { longName: 'Ukrainian', code: 'uk' },
  { longName: 'Vietnamese', code: 'vi' },
];
