import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton } from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';

interface SvlEmptyDataProps {
  error: Boolean;
  noIab?: Boolean;
  setActiveStep?: Function;
  code?: any;
}
const SvlEmptyData = ({ error, noIab = false, setActiveStep, code = null }: SvlEmptyDataProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/smart-vendor-list');
  const { navigateTo } = useSPARouter();

  const handleRedirectToCr = () => {
    return navigateTo('/agnostik-compliance-report');
  };
  const handleRedirectToSelection = () => {
    setActiveStep(0);
  };
  return (
    <>
      <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
        {error ? (
          <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
            <img
              alt="agnostik-logo"
              className="w-[220px] mt-[140px] mb-xs"
              src={code === 403 ? `${ASSETS_URL}/assets/img/unauthorized.svg` : `${ASSETS_URL}/assets/img/Unexpected-error.png`}
            />
            <span className="text-center font-semibold text-[18px] text-primary-pink-5 mb-xxxs" data-cy="error-loading">
              {code === 403 ? 'Access denied' : 'Unexpected error'}
            </span>
            {code === 403 ? (
              <span className="text-center text-primary-blue-6 text-h3">
                Your user role doesn&apos;t grant access to the requested feature. <br />
                Visit https://support.didomi.io/ for more information about user roles.
              </span>
            ) : (
              <span className="text-center text-primary-blue-6 text-h3">
                The APIs are not responding, <br />
                please try again in few minutes.
              </span>
            )}
          </div>
        ) : (
          <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
            <img alt="agnostik-logo" className="w-[220px] mt-[140px] mb-xs" src={`${ASSETS_URL}/assets/img/No-result-found.png`} />
            <span className="text-center text-primary-blue-4 mb-xxxs body-big-semibold">It&apos;s empty here!</span>
            <span className="text-center text-primary-blue-6 text-[20px]">
              {!noIab ? 'You need to run at least a compliance report to get a domain here.' : 'There are no vendors in the selection.'}
            </span>
            <div className="w-full flex justify-around mt-m">
              {!noIab ? (
                <DidomiButton onClick={handleRedirectToSelection}>Go back</DidomiButton>
              ) : (
                <DidomiButton onClick={handleRedirectToCr}>Go to Compliance Report</DidomiButton>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { SvlEmptyData };
