import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Vendor } from '@types';

const isIAB1Vendor = (vendor: Vendor) => vendor.namespaces && Object.keys(vendor.namespaces).length === 1 && vendor.namespaces.iab > 0;

// Check if vendor belongs to the organization + Global + Not IAB v1
const isVendorAvailable = (currentOrgId: string) => (vendor: Vendor) => {
  return (!vendor.organization_id || vendor.organization_id === currentOrgId) && !isIAB1Vendor(vendor);
};

/**
 * Fetches the full list of vendors(max 5000)
 *
 */
export const useVendors = (regulationId?: string, options?: UseQueryOptions<Vendor[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchVendors = async () => {
    // $regulation_id param allows to fetch vendors with assigned SPI for specific regulation
    const withRegulationId = regulationId ? `&$regulation_id=${regulationId}` : '';
    const response = await axiosWithInterceptors.get<PaginatedResponse<Vendor>>(
      `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners?$translations=true&$limit=5000${withRegulationId}`,
    );
    const filteredVendors = response.data.data.filter(isVendorAvailable(organizationId));
    return filteredVendors;
  };

  return useQuery<Vendor[], AxiosError>(['vendors'], fetchVendors, options);
};
