export const formatDate = (date: number | Date | null): string => {
  if (!date) return '-';
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(date));
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(date));
  let h = new Intl.DateTimeFormat('eu', { hour: '2-digit' }).format(new Date(date));
  let m = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(new Date(date)).padStart(2, '0'); // transforms 15:8 to 15:08
  return `${mo} ${da} ${ye} at ${h}:${m}`.replace(' h', '');
};

export const formatDateMMDDYY = (date: number | Date | null): string => {
  if (!date) return '-';
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(date));
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(date));
  return `${mo} ${da} ${ye}`;
};

export const sortArrayByDate = (list, dateKey) => {
  return list.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[dateKey]).valueOf() - new Date(a[dateKey]).valueOf();
  });
};

export const isDateOlderThanOneDay = (date: Date): boolean => {
  date = new Date(date);
  const currentDate = new Date();
  const oneDayInMs = 86400000; // (1000 * 60 * 60 * 24)
  return currentDate.getTime() - date.getTime() > oneDayInMs;
};
