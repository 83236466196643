import { supportedLanguages } from './supportedLanguages';

export const AVAILABLE_LANGUAGES = supportedLanguages.map(sL => sL.code);

export const DEFAULT_EMPTY_TRANSLATION = '-';

export type Translation = { [lang: string]: string } | string | null | undefined;

/**
 * Returns the language code that is going to be selected from the propertyObject
 * based on the string availability.
 *
 * @returns {(string|null)} -> null means the translation is directly a string or empty translation object.
 */
export const getSelectedLanguageCode = (propertyObject: Translation, defaultLanguageCode: string = 'en') => {
  if (typeof propertyObject === 'string') {
    return null;
  }
  if (propertyObject?.[defaultLanguageCode]) {
    return defaultLanguageCode;
  }
  // If there's no value in default language we return a value in any language
  const languageAvailable = AVAILABLE_LANGUAGES.find(language => !!propertyObject?.[language]);
  return languageAvailable ?? null;
};

export const getTranslatedValue = (propertyObject: Translation, defaultValue: string = '-', defaultLanguageCode: string = 'en', fillIfEmpty = true) => {
  const languageCode = fillIfEmpty ? getSelectedLanguageCode(propertyObject, defaultLanguageCode) : defaultLanguageCode;

  if (languageCode === null) {
    if (typeof propertyObject === 'string') {
      return propertyObject;
    }
    // This case will be possible when the propertyObject is empty -> `{}`
    return defaultValue;
  }
  return propertyObject?.[languageCode] || defaultValue;
};
