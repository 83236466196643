import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

type Tab = 'property' | 'vendor';

enum tabValues {
  'property' = 0,
  'vendor' = 1,
}

const defaultActiveTab = {
  defaultTab: 'property',
};

interface PaginationOptions {
  defaultTab?: Tab;
}

export const useDetailTabActiveStatus = (options?: PaginationOptions) => {
  const { defaultTab } = {
    ...defaultActiveTab,
    ...options,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const activeStep = useMemo(() => {
    return tabValues[searchParams.get('activeTab')] || tabValues[defaultTab];
  }, [searchParams, defaultTab]);

  useEffect(() => {
    if (!searchParams.get('activeTab')) {
      searchParams.set('activeTab', tabValues[0]);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const setActiveStep = useCallback(
    (selectedTab: number) => {
      searchParams.set('activeTab', tabValues[selectedTab]);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    activeStep,
    setActiveStep,
  };
};
