import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';

const SvlEmptyTable = () => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/smart-vendor-list');
  return (
    <>
      <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
        <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg">
          <img alt="agnostik-logo" className="w-[220px] mt-[140px] mb-xs" src={`${ASSETS_URL}/assets/img/No-result-found.png`} />
          <span className="text-center text-primary-blue-4 mb-xxxs body-big-semibold">No results</span>
        </div>
      </div>
    </>
  );
};

export { SvlEmptyTable };
