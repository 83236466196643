import { Vendor } from '@types';

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

// Non-deprecated vendors with default purposes
const isActiveVendor = (vendor: Vendor) => vendor.deprecated !== true && vendor.default_purposes_id?.length > 0;

export const filterVendors = (vendors: Vendor[], organizationId) => {
  return vendors.filter(metadataPartner => {
    // test: Custom bound to the customer's organization_id
    if (isActiveVendor(metadataPartner) && (!metadataPartner.namespaces || metadataPartner.namespaces?.custom) && metadataPartner.organization_id === organizationId) {
      return true;
    }

    // test: Built-in replica (custom)
    if (
      isActiveVendor(metadataPartner) &&
      (!metadataPartner.namespaces || isObjectEmpty(metadataPartner.namespaces) || metadataPartner.namespaces?.didomi) &&
      metadataPartner.organization_id === organizationId
    ) {
      return true;
    }

    // test: Built-in
    if (
      isActiveVendor(metadataPartner) &&
      (!metadataPartner.namespaces || isObjectEmpty(metadataPartner.namespaces) || metadataPartner.namespaces?.didomi) &&
      !metadataPartner.organization_id
    ) {
      return true;
    }

    return false;
  });
};
